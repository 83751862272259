import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

class BrowseIndex extends React.Component {

    render() {

        function unescapeHtml(safe) {
            return safe
                .replace(/&amp;/g, "&")
                .replace(/&mdash;/g, "-")
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&quot;/g, "\"")
                .replace(/&hellip;/g, "")
                .replace(/&#10;/g, "\n")
                .replace(/&#039;/g, "'");
        }

        let games = this.props.data.allGame.edges;
        return (
            <Layout>
                <Helmet
                    title="Parlour Party"
                    meta={[
                        { name: 'description', content: 'Board Game Service' },
                        { name: 'keywords', content: 'Board Game Service' },
                    ]}
                >
                </Helmet>

                <section id="banner" className="major">
                    <div className="inner">
                        <header className="major">
                            <h1>Game Library</h1>
                        </header>
                        <div className="content">
                            <p>
                                Below is a list of our game library. Click each game to learn more.
                            </p>
                        </div>
                    </div>
                </section>

                <div id="main">
                <section id="one" className="tiles small">
                    {
                        games.map(game => {
                            game = game.node;
                            if (true) {
                                let tinydescription = unescapeHtml(game.bgg.description).substring(0, 200) + "...";
                                return (
                                    <article style={{backgroundImage: `url(${game.bgg.image})`}}>
                                        <header className="major">
                                            <h3>{game.pp.name}</h3>
                                            <p>{tinydescription}</p>
                                        </header>
                                        <Link to={`game-info/${game.pp.bggid}`} className="link primary"></Link>
                                    </article>
                                )
                            }
                        })
                    }
                </section>




                    {/* <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>How It Works</h2>
                            </header>
                            <p>Learn about how our service works.</p>
                            <ul className="actions">
                                <li><Link to="/how-it-works" className="button next">Get Started</Link></li>
                            </ul>
                        </div>
                    </section> */}
                </div>

            </Layout>
        )
    }
}

export default BrowseIndex


export const query = graphql`
  query BrowseQuery {
    allGame {
    edges {
        node {
            id
            bgg {
                image
                minPlayers
                maxPlayers
                playingTime
                description
            }
            pp {
                name
                bggurl
                bggid
                count
                bestPlayerCount
                whoIsThisGameFor
                gamerLevel
                benefitsOfThisGame
                status
                recommendedVenueType
            }
        }
      }
    }
  }
`